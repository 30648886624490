_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "\u041c\u043e\u043b\u044f, \u043f\u0440\u0435\u0434\u043e\u0441\u0442\u0430\u0432\u0435\u0442\u0435 \u0441\u044a\u0432\u043f\u0430\u0434\u0430\u0449\u0430 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442 \u0437\u0430 {0}",
	"crm.label.field":"\u041f\u043e\u043b\u0435",//no i18n
"crm.label.value":"\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438\u0442\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 {0}",//no i18n
"crm.mb.field.common.splc":"\u041d\u0435 \u0441\u0435 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u0432\u0430\u0442 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u043d\u0438 \u0441\u0438\u043c\u0432\u043e\u043b\u0438. \u0412\u044a\u0432\u0435\u0434\u0435\u0442\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442.",//no i18n
	"crm.label.field.plural":"\u043f\u043e\u043b\u0435\u0442\u0430",//no i18n
	"crm.label.in.minutes":"{0} (\u0432 \u043c\u0438\u043d\u0443\u0442\u0438)",//no i18n
	"crm.territory.addterritory":"\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0442\u0435\u0440\u0438\u0442\u043e\u0440\u0438\u044f",//no i18n
"crm.title.edit.territory":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u0442\u0435\u0440\u0438\u0442\u043e\u0440\u0438\u044f",//no i18n
"crm.territory.title.assign.territories":"\u041d\u0430\u0437\u043d\u0430\u0447\u0438 \u0442\u0435\u0440\u0438\u0442\u043e\u0440\u0438\u0438",//no i18n
	"crm.security.roles.list":"\u0421\u043f\u0438\u0441\u044a\u043a \u0441 \u0440\u043e\u043b\u0438",//no i18n
"crm.security.roles.lookup.info":"\u041c\u043e\u043b\u044f, \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0440\u043e\u043b\u044f \u043e\u0442 \u0441\u043f\u0438\u0441\u044a\u043a\u0430.",//no i18n
	"crm.label.context.help":"\u041f\u043e\u043c\u043e\u0449",//no i18n
	"crm.label.from":"\u041e\u0442",//no i18n
"crm.label.to":"\u0414\u043e",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u041e\u0442 \u0434\u0430\u0442\u0430",//no i18n
"workflow.option.webhookFailure.toDate":"\u0414\u043e \u0434\u0430\u0442\u0430",//no i18n
"crm.custom.field.less.than.equalto":"{0} \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0435  \u043f\u043e-\u043c\u0430\u043b\u043a\u043e \u043e\u0442 \u0438\u043b\u0438 \u0440\u0430\u0432\u043d\u043e \u043d\u0430 {1}.",//no i18n
	"crm.wf.usage.date.criteria.error.msg":"\u201e\u041e\u0442 \u0434\u0430\u0442\u0430\u201d \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u0433\u043e\u043b\u044f\u043c\u043e \u043e\u0442 \u201e\u0414\u043e \u0434\u0430\u0442\u0430\u201d.",//no i18n
  "crm.template.listview.search.no.results":"\u041d\u0435 \u0441\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442\u0438",//No I18n
  "crm.label.tag.new":"\u041d\u043e\u0432 \u0435\u0442\u0438\u043a\u0435\u0442",//No I18n
  "crm.label.enter.tag":"\u0412\u044a\u0432\u0435\u0436\u0434\u0430\u043d\u0435 \u043d\u0430 \u0435\u0442\u0438\u043a\u0435\u0442\u0438",//No I18n
  "crux.comboBox.max.limit":"\u041d\u0435 \u043c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 {0} {1}.",//NO I18n
  "Administrator":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440",//No I18n
  "Standard":"\u0421\u0442\u0430\u043d\u0434\u0430\u0440\u0442",//NO I18n
	"crm.button.add":"\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435",//NO I18n
	"crm.label.users":"\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438", //NO I18n
  "crm.workflow.alert.roles":"\u0420\u043e\u043b\u0438", //NO I18n
  "crm.security.groups":"\u0413\u0440\u0443\u043f\u0438", //NO I18n
	"crm.label.available" : "\u041d\u0430\u043b\u0438\u0447\u043d\u043e", //NO I18n
	"crm.label.assign.manually" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0438", //NO I18n
	"crm.globalsearch.option.all": "\u0412\u0441\u0438\u0447\u043a\u0438", //NO I18n
	"webform.status.Active":"\u0410\u043a\u0442\u0438\u0432\u043d\u043e", //NO I18n
	"Inactive":"\u041d\u0435\u0430\u043a\u0442\u0438\u0432\u0435\u043d", //NO I18n
  "Confirmed":"\u041f\u043e\u0442\u0432\u044a\u0440\u0434\u0435\u043d\u043e", //NO I18n
  "crm.user.component.unconfirmed":"\u041d\u0435\u043f\u043e\u0442\u0432\u044a\u0440\u0434\u0435\u043d\u0438",//no i18n
  "DeletedUser":"\u0418\u0437\u0442\u0440\u0438\u0442\u043e", //NO I18n
  "crm.feed.group.admin":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440", //NO I18n
  "crm.ln.lable.current":"\u0422\u0435\u043a\u0443\u0449\u043e", //NO I18n
	"crm.label.selected": "\u0418\u0437\u0431\u0440\u0430\u043d\u043e",//NO I18n
	"crm.auditlog.user": "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b", //NO I18n
	"cob.role": "\u0420\u043e\u043b\u044f", //NO I18n
	"zoho.email": "\u0418\u043c\u0435\u0439\u043b", //NO I18n
	"Profile": "\u041f\u0440\u043e\u0444\u0438\u043b", //NO I18n
	"crm.security.group.users.empty": "\u041d\u044f\u043c\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438.", //NO I18n
	"crm.label.picklist.none": "\u041d\u0438\u043a\u043e\u0439", //NO I18n
	"crm.label.notSelected" : "\u041d\u0435 \u0435 \u0438\u0437\u0431\u0440\u0430\u043d\u043e",//NO I18n
	"AM" : "\u043f\u0440\u0435\u0434\u0438 \u043e\u0431\u0435\u0434",//NO I18n
	"Call" : "\u041f\u043e\u0432\u0438\u043a\u0432\u0430\u043d\u0435",//NO I18n
	"crm.phoneNo.Link.Title" : "\u041e\u0431\u0430\u0434\u0438 \u0441\u0435 \u043f\u043e Skype",//NO I18n
	"crm.button.cancel" : "\u041e\u0442\u043c\u044f\u043d\u0430",//NO I18n
	"crm.button.save" : "\u0417\u0430\u043f\u0430\u0437\u0438",//NO I18n
	"crm.no.data.found" : "\u041d\u0435 \u0441\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 \u0434\u0430\u043d\u043d\u0438.",//NO I18n
	"crm.usrpop.non.selected" : "\u0418\u0437\u0431\u0440\u0430\u043d\u0438 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438", //NO I18n
	"crm.zti.label.user": "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0441\u043a\u043e \u0438\u043c\u0435", //NO I18n
	"crm.label.no.options.found" : "\u041d\u044f\u043c\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 \u043e\u043f\u0446\u0438\u0438.",//No I18n
	"crm.globalsearch.search.title" : "\u0422\u044a\u0440\u0441\u0435\u043d\u0435",//No I18n
	"None" : "\u041d\u0438\u043a\u043e\u0439",//No I18n
	"crm.label.criteria.pattern" : "\u041c\u043e\u0434\u0435\u043b \u043d\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438",//No I18n
	"crm.label.edit.criteria.pattern" : "\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u043c\u043e\u0434\u0435\u043b",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u0421\u043a\u043e\u0431\u0438\u0442\u0435 \u0437\u0430 \u043c\u043e\u0434\u0435\u043b\u0430 \u043d\u0435 \u0441\u044a\u0432\u043f\u0430\u0434\u0430\u0442.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0438 \u0441\u043a\u043e\u0431\u0438, \u043e\u0431\u0433\u0440\u0430\u0436\u0434\u0430\u0449\u0438 \u0443\u0441\u043b\u043e\u0432\u0435\u043d \u043e\u043f\u0435\u0440\u0430\u0442\u043e\u0440 (\u0443\u0441\u043b\u043e\u0432\u043d\u0438 \u043e\u043f\u0435\u0440\u0430\u0442\u043e\u0440\u0438).",//No I18n
	"crm.criteria.number.notmatch.check" : "\u041c\u043e\u043b\u044f, \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u0442\u0435 \u043c\u043e\u0434\u0435\u043b\u0430 \u043d\u0430 {0}.",//No I18n
	"criteria.error.alert.other.params" : "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0441\u044a\u0434\u044a\u0440\u0436\u0430\u043d\u0438\u0435 \u0432 \u0442\u043e\u0437\u0438 \u043c\u043e\u0434\u0435\u043b.", //No I18n
	"crm.label.search.for.users": "\u0422\u044a\u0440\u0441\u0438 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u041c\u043e\u0434\u0435\u043b\u044a\u0442 \u043d\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438\u0442\u0435 \u043d\u0435 \u0441\u044a\u0432\u043f\u0430\u0434\u0430 \u0441 \u0443\u0441\u043b\u043e\u0432\u0438\u044f\u0442\u0430, \u043a\u043e\u0438\u0442\u043e \u0441\u0442\u0435 \u0438\u0437\u0431\u0440\u0430\u043b\u0438.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u041c\u043e\u0434\u0435\u043b\u044a\u0442 \u043d\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438\u0442\u0435 \u043d\u0435 \u0441\u044a\u0432\u043f\u0430\u0434\u0430 \u0441 \u0443\u0441\u043b\u043e\u0432\u0438\u044f\u0442\u0430, \u043a\u043e\u0438\u0442\u043e \u0441\u0442\u0435 \u0438\u0437\u0431\u0440\u0430\u043b\u0438.", //No I18n
	"and" : "\u0438", //No I18n
	"or" : "\u0438\u043b\u0438", //No I18n
	"crm.label.or" : "\u0418\u041b\u0418", //No I18n
	"crm.label.and" : "\u0418", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u041c\u043e\u043b\u044f, \u0432\u044a\u0432\u0435\u0434\u0435\u0442\u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d \u0435\u0442\u0438\u043a\u0435\u0442 \u043d\u0430 \u043f\u043e\u043b\u0435 \u0432 {0} \u0440\u0435\u0434.", //No I18n
	"crm.criteria.condition.valid.check" : "\u041c\u043e\u043b\u044f, \u043f\u043e\u0441\u043e\u0447\u0435\u0442\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0443\u0441\u043b\u043e\u0432\u0438\u0435 \u0437\u0430 {0}.", //No I18n
	"crm.field.valid.check" : "\u041c\u043e\u043b\u044f, \u0432\u044a\u0432\u0435\u0434\u0435\u0442\u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d {0}.", //No I18n
	"crm.custom.field.less.than.to" : "\u0414\u0438\u0430\u043f\u0430\u0437\u043e\u043d <i>\u041e\u0442</i> \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0435 \u043f\u043e-\u0433\u043e\u043b\u044f\u043c \u043e\u0442 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d <i>\u0414\u043e</i>.", //No I18n
	"crm.alert.label.savepattern" : "\u0417\u0430\u043f\u0430\u0437\u0435\u0442\u0435 \u043c\u043e\u0434\u0435\u043b\u0430, \u043f\u0440\u0435\u0434\u0438 \u0434\u0430 \u0441\u043c\u0435\u043d\u0438\u0442\u0435 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438\u0442\u0435.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u041d\u0435 \u043c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u0435 \u0434\u043e\u043f\u044a\u043b\u043d\u0438\u0442\u0435\u043b\u043d\u0438 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438.",//No I18n
	"is" : "\u0435",//No I18n
	"isn\'t" : "\u043d\u0435 \u0435",//No I18n
	"contains" : "\u0441\u044a\u0434\u044a\u0440\u0436\u0430",//No I18n
	"doesn\'t contain" : "\u043d\u0435 \u0441\u044a\u0434\u044a\u0440\u0436\u0430",//No I18n
	"starts with" : "\u0437\u0430\u043f\u043e\u0447\u0432\u0430 \u0441",//No I18n
	"ends with" : "\u0441\u0432\u044a\u0440\u0448\u0432\u0430 \u0441",//No I18n
	"is empty" : "\u0435 \u043f\u0440\u0430\u0437\u043d\u043e",//No I18n
	"is not empty" : "\u043d\u0435 \u0435 \u043f\u0440\u0430\u0437\u043d\u043e",//No I18n
	"is before" : "\u0435 \u043f\u0440\u0435\u0434\u0438",//No I18n
	"is after" : "\u0435 \u0441\u043b\u0435\u0434",//No I18n
	"between" : "\u043c\u0435\u0436\u0434\u0443",//No I18n
	"not between" : "\u043d\u0435 \u0435 \u043c\u0435\u0436\u0434\u0443",//No I18n
	"Today" : "\u0414\u043d\u0435\u0441",//No I18n
	"Tommorow" : "\u0423\u0442\u0440\u0435",//No I18n
	"Tommorow Onwards" : "\u041e\u0442 \u0443\u0442\u0440\u0435",//No I18n
	"Yesterday" : "\u0412\u0447\u0435\u0440\u0430",//No I18n
	"Till Yesterday" : "\u0414\u043e \u0432\u0447\u0435\u0440\u0430",//No I18n
	"Last Month" : "\u041f\u043e\u0441\u043b\u0435\u0434\u0435\u043d \u043c\u0435\u0441\u0435\u0446",//No I18n
	"Current Month" : "\u0422\u0435\u043a\u0443\u0449 \u043c\u0435\u0441\u0435\u0446", //No I18n
	"Next Month" : "\u0421\u043b\u0435\u0434\u0432\u0430\u0449 \u043c\u0435\u0441\u0435\u0446", //No I18n
	"Last Week" : "\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u0430 \u0441\u0435\u0434\u043c\u0438\u0446\u0430", //No I18n
	"Current Week" : "\u0422\u0435\u043a\u0443\u0449\u0430 \u0441\u0435\u0434\u043c\u0438\u0446\u0430", //No I18n
	"Next Week" : "\u0421\u043b\u0435\u0434\u0432\u0430\u0449\u0430 \u0441\u0435\u0434\u043c\u0438\u0446\u0430", //No I18n
	"Age in Days" : "\u0412\u044a\u0437\u0440\u0430\u0441\u0442 \u0432 \u0434\u043d\u0438", //No I18n
	"Due in Days" : "\u0414\u044a\u043b\u0436\u0438\u043c \u0432 \u0434\u043d\u0438", //No I18n
	"Scheduled" : "\u041d\u0430\u0441\u0440\u043e\u0447\u0435\u043d", //No I18n
	"Attended Dialled" : "\u0423\u0447\u0430\u0441\u0442\u0432\u0430\u0449 \u0434\u0430\u0439\u043b\u0435\u0440", //No I18n
	"Unattended Dialled" : "\u041d\u0435\u0443\u0447\u0430\u0441\u0442\u0432\u0430\u0449 \u0434\u0430\u0439\u043b\u0435\u0440", //No I18n
	"Overdue" : "\u041f\u0440\u043e\u0441\u0440\u043e\u0447\u0435\u043d", //No I18n
	"Cancelled" : "\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043e", //No I18n
	"Received" : "\u041f\u043e\u043b\u0443\u0447\u0435\u043d\u043e", //No I18n
	"Missed" : "\u041f\u0440\u043e\u043f\u0443\u0441\u043d\u0430\u0442", //No I18n
	"crm.alert.character.not.allowed" : "{0} \u043d\u0435 \u0435 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u043e", //No I18n
	"crm.condition.in.last" : "\u0412 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u043e\u0442\u043e", //No I18n
	"crm.zinvoice.dueIn" : "\u0434\u044a\u043b\u0436\u0438\u043c \u0441\u043b\u0435\u0434", //No I18n
	"on" : "\u041d\u0430",//No I18n
	"before" : "\u043f\u0440\u0435\u0434\u0438",//No I18n
	"crm.label.general.small.after" : "\u0441\u043b\u0435\u0434",//No I18n
	"crm.thisweek" : "\u0422\u0430\u0437\u0438 \u0441\u0435\u0434\u043c\u0438\u0446\u0430",//No I18n
	"crm.label.this.month" : "\u0422\u043e\u0437\u0438 \u043c\u0435\u0441\u0435\u0446",//No I18n
	"crm.thisyear" : "\u0422\u0430\u0437\u0438 \u0433\u043e\u0434\u0438\u043d\u0430",//No I18n
	"crm.source.user.and.system" : "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b \u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.source.user.or.system" : "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b \u0438\u043b\u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.label.system2" : "\u0421\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.source.user.only" : "\u0421\u0430\u043c\u043e \u043e\u0442 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b",//No I18n
	"crm.source.system.only" : "\u0421\u0430\u043c\u043e \u043e\u0442 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.condition.till.today" : "\u0414\u043e \u0434\u043d\u0435\u0441",//No I18n
	"game.month.left" : "1 \u043c\u0435\u0441\u0435\u0446",//No I18n
	"game.months.left" : "{0} \u043c\u0435\u0441\u0435\u0446\u0430",//No I18n
	"crm.condition.last.30.days" : "\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 30 \u0434\u043d\u0438",//No I18n
	"crm.condition.last.60.days" : "\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 60 \u0434\u043d\u0438",//No I18n
	"crm.condition.last.90.days" : "\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 90 \u0434\u043d\u0438",//No I18n
	"crm.label.filter.typehere" : "\u041f\u0438\u0448\u0438 \u0442\u0443\u043a", //No I18N
	"crm.filter.is.not" : "\u043d\u0435 \u0435", //No I18n
	"crm.condition.until.now" : "\u0414\u043e \u0441\u0435\u0433\u0430",//No I18n
	"crm.filter.email.isblocked" : "\u0435 \u0431\u043b\u043e\u043a\u0438\u0440\u0430\u043d",//No I18n
	"crm.filter.email.isnotblocked" : "\u043d\u0435 \u0435 \u0431\u043b\u043e\u043a\u0438\u0440\u0430\u043d",//No I18n
	"crm.label.no.results.match" : "\u041d\u044f\u043c\u0430 \u0441\u044a\u0432\u043f\u0430\u0434\u0430\u0449\u0438 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442\u0438",//No I18n
	"crm.label.select.user" : "\u041a\u043b\u0438\u043a\u043d\u0435\u0442\u0435, \u0437\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438.", //No I18n
	"current.logged.in.user": "\u0412\u043f\u0438\u0441\u0430\u043d \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b", //NO I18n
	"current.logged.in.user.definition": "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u044f\u0442, \u043a\u043e\u0439\u0442\u043e \u0438\u043d\u0438\u0446\u0438\u0438\u0440\u0430 \u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435\u0442\u043e \u0437\u0430 \u0437\u0430\u043f\u0438\u0441.", //NO i18n
	"crm.security.group": "\u0413\u0440\u0443\u043f\u0430", //NO I18n
	"crm.security.role": "\u0420\u043e\u043b\u044f", //NO I18n
	"Date" : "\u0414\u0430\u0442\u0430",//No I18n
	"crm.field.valid.decimal.check2" : "\u0414\u0435\u0441\u0435\u0442\u0438\u0447\u043d\u0438\u0442\u0435 \u043c\u0435\u0441\u0442\u0430 \u0437\u0430 \u043f\u043e\u043b\u0435\u0442\u043e <i> {0} </i> \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u0430 \u043f\u043e-\u043c\u0430\u043b\u043a\u0438 \u0438\u043b\u0438 \u0440\u0430\u0432\u043d\u0438 \u043d\u0430 {1}.",//No I18n
	"crm.field.empty.check" : "{0}  \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0435 \u043f\u0440\u0430\u0437\u043d\u0430.",//No I18n
	"crm.lead.prediction.convert.high" : "\u0412\u0438\u0441\u043e\u043a\u0430",//No I18n
	"crm.lead.prediction.convert.medium" : "\u0421\u0440\u0435\u0434\u043d\u0430",//No I18n
	"crm.lead.prediction.convert.low" : "\u041d\u0438\u0441\u043a\u0430",//No I18n
	"crm.lead.prediction.focus.nofollowup":"\u041d\u044f\u043c\u0430 \u0441\u043b\u0435\u0434\u0432\u0430\u0449\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u0432\u0430\u0449\u0430 \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0430",//No I18n
	"crm.lead.prediction.recent.score":"\u0421\u043a\u043e\u0440\u043e\u0448\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u043e\u0442 \u043f\u0440\u043e\u0433\u043d\u043e\u0437\u0430",//No I18n
	"crm.lead.prediction.tooltip":"\u0418\u043c\u0430 \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0437\u0430 \u043a\u043e\u043d\u0432\u0435\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u2013 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u043d\u0430 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442",//No I18n
	"crm.lead.prediction.tooltip.convert":"\u0412\u0415\u0420\u041e\u042f\u0422\u041d\u041e\u0421\u0422",//No I18n
	"crm.lead.prediction.tooltip.score":"\u0420\u0415\u0417\u0423\u041b\u0422\u0410\u0422",//No I18n
	"crm.label.add.note": "\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0437\u0430\u0431\u0435\u043b\u0435\u0436\u043a\u0438", //NO I18n
	"crm.label.simply.by": "\u043e\u0442", //NO I18n
	"crm.general.addnote": "\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0431\u0435\u043b\u0435\u0436\u043a\u0430", //NO I18n
	"crm.general.addtitle": "\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435", //NO I18n
	"crm.label.attach.file": "\u041f\u0440\u0438\u043a\u0430\u0447\u0438 \u0444\u0430\u0439\u043b", //NO I18N
	"crm.button.mass.delete": "\u0418\u0437\u0442\u0440\u0438\u0432\u0430\u043d\u0435", //NO I18N
	"crm.warning.delete.record": "\u041d\u0430\u0438\u0441\u0442\u0438\u043d\u0430 \u043b\u0438 \u0438\u0441\u043a\u0430\u0442\u0435 \u0434\u0430 \u0438\u0437\u0442\u0440\u0438\u0435\u0442\u0435 \"{0}\"?", //NO I18N
	"crm.label.yes": "\u0414\u0430", //NO I18N
	"crm.note.view.previous": "\u041f\u0440\u0435\u0433\u043b\u0435\u0434 \u043d\u0430 \u043f\u0440\u0435\u0434\u043d\u0438\u0442\u0435 \u0437\u0430\u0431\u0435\u043b\u0435\u0436\u043a\u0438", //NO I18N
  "of": "\u043d\u0430", //NO I18N
	"crm.label.notes": "\u0417\u0430\u0431\u0435\u043b\u0435\u0436\u043a\u0438", //NO I18N
	"crm.note.recent.first": "\u0421\u043a\u043e\u0440\u043e\u0448\u043d\u0438 \u043f\u044a\u0440\u0432\u043e", //NO I18N
	"crm.note.recent.last": "\u0421\u043a\u043e\u0440\u043e\u0448\u043d\u0438 \u043d\u0430\u043a\u0440\u0430\u044f", //NO I18N
	"crm.territory.label.only": "{0} \u0441\u0430\u043c\u043e", //no i18n
	"crm.select" : "\u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435",//No I18n
	"crm.button.apply.filter" : "\u041f\u0440\u0438\u043b\u043e\u0436\u0438 \u0444\u0438\u043b\u0442\u044a\u0440",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u041d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0432\u044a\u0432\u0435\u0434\u0435\u0442\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 {0} \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0438 \u0437\u0430 \u0442\u043e\u0432\u0430 \u043f\u043e\u043b\u0435.",//No I18n
	"PM" : "\u0441\u043b\u0435\u0434 \u043e\u0431\u0435\u0434",//No I18n
	"Jan" : "\u042f\u043d.",//No I18n
	"Feb" : "\u0424\u0435\u0444\u0440.",//No I18n
	"Mar" : "\u041c\u0430\u0440\u0442",//No I18n
	"Apr" : "\u0410\u043f\u0440.",//No I18n
	"Jun" : "\u042e\u043d\u0438",//No I18n
	"Jul" : "\u042e\u043b\u0438",//No I18n
	"Aug" : "\u0410\u0432\u0433.",//No I18n
	"Sep" : "\u0421\u0435\u043f\u0442.",//No I18n
	"Oct" : "\u041e\u043a\u0442",//No I18n
	"Nov" : "\u041d\u043e\u0435\u043c.",//No I18n
	"Dec" : "\u0414\u0435\u043a.",//No I18n
	"crm.mb.newversion.msg4" : "\u042f\u0441\u043d\u043e, \u0440\u0430\u0437\u0431\u0440\u0430\u0445!", //no i18n
	"crm.label.More" :"\u041f\u043e\u0432\u0435\u0447\u0435", //no i18n

	 "crm.label.unmapped.stages":"\u043d\u0435\u043e\u0441\u0447\u0435\u0442\u043e\u0432\u043e\u0434\u0435\u043d", //no i18n
	 "crm.wf.summary.label.ShowInstantActions" : "\u041f\u043e\u043a\u0430\u0436\u0438 \u043f\u043e\u0432\u0435\u0447\u0435",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u041f\u043e\u043a\u0430\u0436\u0438 \u043f\u043e-\u043c\u0430\u043b\u043a\u043e",//No I18n

	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"\u0412\u0415\u0420\u041e\u042f\u0422\u041d\u041e\u0421\u0422",//no i18n
	"crm.lead.prediction.tooltip.score":"\u0420\u0415\u0417\u0423\u041b\u0422\u0410\u0422",//no i18n
	"Planned":"\u041f\u043b\u0430\u043d\u0438\u0440\u0430\u043d",//no i18n
	"Invited":"\u041f\u043e\u043a\u0430\u043d\u0435\u043d",//no i18n
	"Sent":"\u0418\u0437\u043f\u0440\u0430\u0442\u0435\u043d (\u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u0430, \u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u043e, \u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u0438)",//no i18n
	"Received":"\u041f\u043e\u043b\u0443\u0447\u0435\u043d\u043e",//no i18n
	"Opened":"\u041e\u0442\u0432\u043e\u0440\u0435\u043d",//no i18n
	"Responded":"\u041e\u0442\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u043e",//no i18n
	"Bounced":"\u041e\u0442\u0445\u0432\u044a\u0440\u043b\u0435\u043d",//no i18n
	"Opted\ Out":"\u041e\u0442\u043a\u0430\u0437\u0430\u043d",//no i18n
	"crm.filter.label.with.open":"\u0421 \u043e\u0442\u0432\u043e\u0440\u0435\u043d\u0438 {0}",//no i18n
	"crm.filter.label.without.open":"\u0411\u0435\u0437 \u043e\u0442\u0432\u043e\u0440\u0435\u043d\u0438 {0}",//no i18n
	"crm.filter.label.without.any":"\u0411\u0435\u0437 \u043d\u0438\u043a\u0430\u043a\u0432\u0438 {0}",//no i18n
	"crm.filter.label.with.module":"\u0421 {0}",//no i18n
	"crm.filter.label.activity.due":"{0} \u0435 \u0437\u0430 \u043f\u0440\u0438\u043a\u043b\u044e\u0447\u0432\u0430\u043d\u0435",//no i18n
	"crm.filter.label.activity.done":"{0} \u0435 \u043d\u0430\u043f\u0440\u0430\u0432\u0435\u043d\u043e",//no i18n
	"Notes":"\u0417\u0430\u0431\u0435\u043b\u0435\u0436\u043a\u0438",//no i18n
	"crm.filter.label.notes.added":"\u0411\u0435\u043b\u0435\u0436\u043a\u0438\u0442\u0435 \u0441\u0430 \u0434\u043e\u0431\u0430\u0432\u0435\u043d\u0438",//no i18n
	"crm.label.filter.email.status":"\u0421\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043d\u0430 \u043d\u0430\u0439-\u043a\u044a\u0441\u0435\u043d \u0438\u043c\u0435\u0439\u043b",//no i18n
	"crm.label.filter.email.clicked":"\u0449\u0440\u0430\u043a\u043d\u0430\u0442\u0438",//no i18n
	"crm.label.filter.email.responded":"\u043e\u0442\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u043e", //no i18n
	"crm.label.filter.email.info":"\u0424\u0438\u043b\u0442\u0440\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u0437\u0430\u043f\u0438\u0441\u0438 \u043d\u0430 \u0431\u0430\u0437\u0430\u0442\u0430 	\u043d\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u043e\u0442\u043e \u0441\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043d\u0430 \u0432\u0430\u0448\u0438\u0442\u0435 		     \u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u0438/\u043f\u043e\u043b\u0443\u0447\u0435\u043d\u0438 \u0438\u043c\u0435\u0439\u043b\u0438.",//no i18n
	"crm.filter.label.sent":"\u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u043e",//no i18n
	"crm.filter.label.not.sent":"\u043d\u0435 \u0435 \u0438\u0437\u043f\u0440\u0430\u0442\u0435\u043d\u043e",//no i18n
	"crm.filter.label.opened":"\u043e\u0442\u0432\u043e\u0440\u0435\u043d\u043e",//no i18n
	"crm.filter.label.not.opened":"\u043d\u0435 \u0435 \u043e\u0442\u0432\u043e\u0440\u0435\u043d\u043e",//no i18n
	"crm.filter.label.received":"\u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043e",//no i18n
	"crm.filter.label.not.received":"\u043d\u0435 \u0435 \u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043e",//no i18n
	"crm.filter.label.bounced":"\u043e\u0442\u0445\u0432\u044a\u0440\u043b\u0435\u043d\u043e",//no i18n
	"crm.filter.label.opened.not.replied":"\u043e\u0442\u0432\u043e\u0440\u0435\u043d\u043e \u0438 \u043d\u0435 \u0435 \u043e\u0442\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u043e", //no i18n
	"crm.filter.label.any":"\u041d\u044f\u043a\u043e\u0435 \u043e\u0442 \u0433\u043e\u0440\u043d\u0438\u0442\u0435",//no i18n
	"crm.zia.config.potential.amount":"{0} \u0441\u0443\u043c\u0430",//no i18n
	"Quote\ Stage":"\u0415\u0442\u0430\u043f \u043d\u0430 {0}",//no i18n
	"crm.module.owner":"\u0421\u043e\u0431\u0441\u0442\u0432\u0435\u043d\u0438\u043a \u043d\u0430 {0}",//no i18n
	"Potential\ Closing\ Date":"\u0414\u0430\u0442\u0430 \u043d\u0430 \u043f\u0440\u0438\u043a\u043b\u044e\u0447\u0432\u0430\u043d\u0435 \u043d\u0430 {0}",//no i18n
	"crm.lead.prediction.likely.convert":"\u0418\u043c\u0430 \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0437\u0430 \u043a\u043e\u043d\u0432\u0435\u0440\u0442\u0438\u0440\u0430\u043d\u0435",//no i18n
	"crm.predictions.feature.label":"\u041f\u0440\u043e\u0433\u043d\u043e\u0437\u0430",//no i18n
	"crm.intelligence.prediction.likelywin":"\u0412\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0434\u0430 \u0441\u043f\u0435\u0447\u0435\u043b\u0438",//no i18n
	"crm.intelligence.prediction.likelylose":"\u0412\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0434\u0430 \u0437\u0430\u0433\u0443\u0431\u0438",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50 \u0448\u0430\u043d\u0441",//no i18n
	"crm.intelligence.prediction.score":"\u041f\u0440\u043e\u0433\u043d\u043e\u0437\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442",//no i18n
	"crm.lead.prediction.recent.score":"\u0421\u043a\u043e\u0440\u043e\u0448\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u043e\u0442 \u043f\u0440\u043e\u0433\u043d\u043e\u0437\u0430",//no i18n
	"crm.intelligence.prediction.lastconv":"\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u0438 3 \u0440\u0430\u0437\u0433\u043e\u0432\u043e\u0440\u0430",//no i18n
	"crm.intelligence.prediction.recordsfocus":"\u0417\u0430\u043f\u0438\u0441\u0438 \u0437\u0430 \u0444\u043e\u043a\u0443\u0441",//no i18n
	"crm.intelligence.prediction.slowmoving":"\u0411\u0430\u0432\u043d\u043e \u0434\u0432\u0438\u0436\u0435\u0449\u043e \u0441\u0435",//no i18n
	"crm.intelligence.prediction.trend.down":"\u041d\u0430\u043f\u043e\u0441\u043b\u0435\u0434\u044a\u043a \u0441 \u0442\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f \u043d\u0430\u0434\u043e\u043b\u0443",//no i18n
	"crm.label.touched.records":"\u0414\u043e\u043a\u043e\u0441\u043d\u0430\u0442\u0438 \u0437\u0430\u043f\u0438\u0441\u0438",//no i18n
	"crm.label.untouched.records":"\u041d\u0435\u0434\u043e\u043a\u043e\u0441\u043d\u0430\u0442\u0438 \u0437\u0430\u043f\u0438\u0441\u0438",//no i18n
	"crm.label.record.action":"\u0417\u0430\u043f\u0438\u0441 \u043d\u0430 \u0434\u0435\u0439\u043d\u043e\u0441\u0442",//no i18n
	"workflow.rule.view.label.Modified":"\u041f\u0440\u043e\u043c\u0435\u043d\u0435\u043d",//no i18n
	"crm.label.not.modified":"\u041d\u0435 \u0435 \u043f\u0440\u043e\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
	"crm.label.related.records.action":"\u0421\u0432\u044a\u0440\u0437\u0430\u043d\u043e \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043d\u0430 \u0437\u0430\u043f\u0438\u0441\u0438",//no i18n
	"Done":"\u0418\u0437\u043f\u044a\u043b\u043d\u0435\u043d\u043e",//no i18n
	"crm.label.not.done":"\u041d\u0435 \u0435 \u0438\u0437\u0432\u044a\u0440\u0448\u0435\u043d\u043e",//no i18n
	"sentiment.model":"\u0421\u0440\u0435\u0434\u043d\u0430 \u043e\u0446\u0435\u043d\u043a\u0430 \u043d\u0430 \u0438\u043c\u0435\u0439\u043b",//no i18n
	"sentiment.criteria.count":"\u0411\u0440\u043e\u0439",//no i18n
	"sentiment.criteria.percentage":"\u041f\u0440\u043e\u0446\u0435\u043d\u0442",//no i18n
	"sentiment.criteria.lastmail":"\u0417\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u044f \u0438\u043c\u0435\u0439\u043b",//no i18n
	"Chats":"\u0427\u0430\u0442\u043e\u0432\u0435",//no i18n
	"Attended":"\u0423\u0447\u0430\u0441\u0442\u0432\u0430\u043b",//no i18n
	"crm.lead.prediction.popup.text":"\u041a\u043e\u0433\u0430\u0442\u043e \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442\u0442\u0430 \u0437\u0430 \u043a\u043e\u043d\u0432\u0435\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u0435 {0}, \u043f\u0440\u043e\u0433\u043d\u043e\u0437\u043d\u0438\u044f\u0442 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043c\u0435\u0436\u0434\u0443 {1}.",//no i18n
	"crm.lead.prediction.popup.final":"\u041c\u043e\u043b\u044f, \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u0440\u0430\u0432\u0438\u043b\u043d\u0438\u044f \u0444\u0438\u043b\u0442\u044a\u0440 \u0438 \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u043e\u0442\u043d\u043e\u0432\u043e.",//no i18n
	"crm.custom.field.less.than.to1":"\u0414\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u201c\u041e\u0442\u201d \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u0433\u043e\u043b\u044f\u043c \u043e\u0442 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u201c\u0414\u043e\u201d.",//no i18n
	"Last\ Activity\ Date":"\u0414\u0430\u0442\u0430 \u043d\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0430 \u0430\u043a\u0442\u0438\u0432\u043d\u043e\u0441\u0442",//no i18n
	"crm.label.vendor.name":"\u0418\u043c\u0435 \u043d\u0430 {0}",//no i18n
	"hours":"\u0447\u0430\u0441\u043e\u0432\u0435",//no i18n
	"days":"\u0434\u043d\u0438",//no i18n
	"weeks":"\u0441\u0435\u0434\u043c\u0438\u0446\u0438",//no i18n
	"months":"\u043c\u0435\u0441\u0435\u0446\u0438",//no i18n
	"years":"\u0433\u043e\u0434\u0438\u043d\u0438",//no i18n
	"crm.label.general.small.after":"\u0441\u043b\u0435\u0434",//no i18n
	"Last\ Week":"\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u0430 \u0441\u0435\u0434\u043c\u0438\u0446\u0430",//no i18n
	"Last\ Month":"\u041f\u043e\u0441\u043b\u0435\u0434\u0435\u043d \u043c\u0435\u0441\u0435\u0446",//no i18n
	"crm.module.name":"\u0418\u043c\u0435 \u043d\u0430 {0}",//no i18n
	"Campaign":"\u041a\u0430\u043c\u043f\u0430\u043d\u0438\u044f",//no i18n
	"Tasks":"\u0417\u0430\u0434\u0430\u0447\u0438",//no i18n
	"Calls":"\u041f\u043e\u0432\u0438\u043a\u0432\u0430\u043d\u0438\u044f",//no i18n
	"Events":"\u0421\u044a\u0431\u0438\u0442\u0438\u044f",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438\u0442\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 {0}",//no i18n
	"crm.chosen.minimum.input.text":"\u041c\u043e\u043b\u044f, \u0432\u044a\u0432\u0435\u0434\u0435\u0442\u0435 {0} \u0438\u043b\u0438 \u043f\u043e\u0432\u0435\u0447\u0435 \u0437\u043d\u0430\u043a\u0430",//no i18n
	"crm.intelligence.prediction.trendup":"\u0422\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f \u043d\u0430\u0433\u043e\u0440\u0435",//no i18n
	"crm.intelligence.prediction.trenddown":"\u0422\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f \u043d\u0430\u0434\u043e\u043b\u0443",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"\u0417\u0430\u0432\u044a\u0440\u0448\u0435\u043d\u0438" ,//no i18n
	"crm.label.success":"\u0423\u0441\u043f\u0435\u0448\u0435\u043d" ,//no i18n
	"crm.label.Failure":"\u041d\u0435\u0443\u0441\u043f\u0435\u0445" ,//no i18n
	"Both":"\u0418 \u0434\u0432\u0435\u0442\u0435" ,//no i18n
	"crm.condition.cannot.empty":"\u0423\u0441\u043b\u043e\u0432\u0438\u0435\u0442\u043e \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0435 \u043f\u0440\u0430\u0437\u043d\u043e.",//no i18n
	"crm.condition.last.30.days":"\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 30 \u0434\u043d\u0438",//no i18n
	"crm.condition.last.60.days":"\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 60 \u0434\u043d\u0438",//no i18n
	"crm.condition.last.90.days":"\u043f\u0440\u0435\u0437 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0442\u0435 90 \u0434\u043d\u0438",//no i18n
	"crm.sentiment.Positive":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u0435\u043d",//no i18n
	"crm.sentiment.Negative":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u0435\u043d",//no i18n
	"sentiment.positiveandnegative":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u043d\u0438 \u0438 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u043d\u0438",//no i18n
	"sentiment.positiveornegative":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u043d\u0438 \u0438\u043b\u0438 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u043d\u0438",//no i18n
	"sentiment.positiveonly":"\u0421\u0430\u043c\u043e \u043f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u043d\u0438",//no i18n
	"sentiment.negativeonly":"\u0421\u0430\u043c\u043e \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u043d\u0438",//no i18n
	"crm.sentiment.Neutral":"\u041d\u0435\u0443\u0442\u0440\u0430\u043b\u0435\u043d",//no i18n
	"crm.filters.select.campaign.type":"\u0418\u0437\u0431\u043e\u0440 \u043d\u0430 {0} \u0442\u0438\u043f",//no i18n
	"crm.filters.select.campaign.status":"\u0418\u0437\u0431\u043e\u0440 \u043d\u0430 {0} \u0441\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435",//no i18n
	"campaign.Member" : "\u0427\u043b\u0435\u043d",//no i18n
	"Service":"\u0443\u0441\u043b\u0443\u0433\u0430",//no i18n
	"Activities":"\u0414\u0435\u0439\u043d\u043e\u0441\u0442\u0438",//no i18n
	"crm.livedesk.pot.nextdays":"\u0421\u043b\u0435\u0434\u0432\u0430\u0449\u0438\u0442\u0435 {0} \u0434\u043d\u0438",//no i18n
	"Today\ +\ Overdue":"\u0414\u043d\u0435\u0448\u043d\u0438 + \u043f\u0440\u043e\u0441\u0440\u043e\u0447\u0435\u043d\u0438",//no i18n
	"crm.source.user.and.system":"\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b \u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
	"crm.source.user.or.system":"\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b \u0438\u043b\u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
	"User":"\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b",//no i18n
	"crm.source.user.only":"\u0421\u0430\u043c\u043e \u043e\u0442 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b",//no i18n
	"crm.source.system.only":"\u0421\u0430\u043c\u043e \u043e\u0442 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
	"Scheduled":"\u041d\u0430\u0441\u0440\u043e\u0447\u0435\u043d",//no i18n
	"Attended\ Dialled":"\u0423\u0447\u0430\u0441\u0442\u0432\u0430\u0449 \u0434\u0430\u0439\u043b\u0435\u0440",//no i18n
	"Unattended\ Dialled":"\u041d\u0435\u0443\u0447\u0430\u0441\u0442\u0432\u0430\u0449 \u0434\u0430\u0439\u043b\u0435\u0440",//no i18n
	"Cancelled":"\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
	"crm.filter.email.isblocked":"\u0435 \u0431\u043b\u043e\u043a\u0438\u0440\u0430\u043d",//no i18n
	"crm.filter.email.isnotblocked":"\u043d\u0435 \u0435 \u0431\u043b\u043e\u043a\u0438\u0440\u0430\u043d",//no i18n
	"condition.till.now":"\u0414\u043e\u0441\u0435\u0433\u0430",//no i18n
	"crm.recurring.no.months":"{0} \u043c\u0435\u0441\u0435\u0446\u0430",//no i18n
	"crm.lead.prediction.tooltip":"\u0418\u043c\u0430 \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0437\u0430 \u043a\u043e\u043d\u0432\u0435\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u2013 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u043d\u0430 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442",//no i18n
	"crm.website.activity":"\u0410\u043a\u0442\u0438\u0432\u043d\u043e\u0441\u0442 \u043d\u0430 \u0441\u0430\u0439\u0442",//no i18n
	"crm.label.By":"\u041e\u0442",//no i18n
	"crm.chosen.searching.text":"\u0422\u044a\u0440\u0441\u0435\u043d\u0435...",//no i18n
	"crm.label.memberstatus.is":"\u0438 \u0441\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435\u0442\u043e \u043d\u0430 \u0447\u043b\u0435\u043d\u0430 \u0435",//no i18n
	"crm.events.duration":"\u041f\u0440\u043e\u0434\u044a\u043b\u0436\u0438\u0442\u0435\u043b\u043d\u043e\u0441\u0442",//no i18n
	"crm.title.clear.name":"\u0418\u0437\u0447\u0438\u0441\u0442\u0438",//no i18n
	"crm.label.status.is":"\u0438 \u0441\u0442\u0430\u0442\u0443\u0441\u044a\u0442 \u0435",//no i18n
	"zia.last3.help":"\u0420\u0430\u0437\u0433\u043e\u0432\u043e\u0440\u044a\u0442 \u0432\u043a\u043b\u044e\u0447\u0432\u0430 \u043e\u0431\u0430\u0436\u0434\u0430\u043d\u0438\u044f, \u0437\u0430\u0434\u0430\u0447\u0438, {0}, \u043f\u043e\u043b\u0443\u0447\u0435\u043d \u0438\u043c\u0435\u0439\u043b, \u0431\u0435\u043b\u0435\u0436\u043a\u0438, \u043f\u043e\u0441\u0435\u0449\u0435\u043d\u0438\u044f, \u043a\u043e\u043c\u0435\u043d\u0442\u0430\u0440\u0438 \u0432 \u0441\u043e\u0446\u0438\u0430\u043b\u043d\u0438\u0442\u0435 \u043c\u0440\u0435\u0436\u0438, \u0437\u0430\u044f\u0432\u043a\u0438 \u0437\u0430 \u043f\u043e\u0434\u0434\u0440\u044a\u0436\u043a\u0430 \u043e\u0442 Desk.",//no i18n
	"crm.label.tag.related.to":"\u0441\u0432\u044a\u0440\u0437\u0430\u043d \u0441",//no i18n
	"crm.label.account.created" : "\u0429\u0435 \u0431\u044a\u0434\u0435 \u0441\u044a\u0437\u0434\u0430\u0434\u0435\u043d \u043d\u043e\u0432 {0}.",//No I18n
	"crm.krp.no.records.found" : "\u041d\u044f\u043c\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 {0}",//No I18n
	"crm.module.new" : "\u041d\u043e\u0432 {0}",//No I18n
	"crm.label.view" : "\u041f\u0440\u0435\u0433\u043b\u0435\u0434\u0430\u0439",//No I18n
	//end-filter related keys
	"crm.nsocial.customers" : "Z\u00e1kazn\u00edci",//No I18n
	"crm.nsocial.open.potential" : "Otev\u0159eno {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Jin\u00e9",//No i18n
	"crm.field.length.check" : "{0} \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442 \u043d\u0430\u0434\u0432\u0438\u0448\u0430\u0432\u0430 \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u0430\u0442\u0430 \u0434\u044a\u043b\u0436\u0438\u043d\u0430.", //No I18n
	"crm.lower.now":"\u0441\u0435\u0433\u0430",//no i18n
	"crm.time.min.ago":"\u043f\u0440\u0435\u0434\u0438 {0} \u043c\u0438\u043d\u0443\u0442\u0438",//no i18n
	"crm.time.mins.ago":"\u043f\u0440\u0435\u0434\u0438 {0} \u043c\u0438\u043d\u0443\u0442\u0438",//no i18n
	"crm.time.hr.ago":"\u043f\u0440\u0435\u0434\u0438 {0} \u0447\u0430\u0441",//no i18n
	"crm.time.hrs.ago":"\u043f\u0440\u0435\u0434\u0438 {0} \u0447\u0430\u0441\u0430", //no i18n
	"AllUsers": "\u0412\u0441\u0438\u0447\u043a\u0438 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0438", //no i18n
	"crm.label.search":"\u0422\u044a\u0440\u0441\u0435\u043d\u0435",//no i18n
	"crm.api.filterby":"\u0424\u0438\u043b\u0442\u0440\u0438\u0440\u0430\u0439 \u043f\u043e",//no i18n
	"crm.customview.nofields.found":"\u041d\u0435\u0441\u044a\u0432\u043f\u0430\u0434\u0430\u0449\u0438 \u043f\u043e\u043b\u0435\u0442\u0430",//no i18n
	"crm.setup.system.ziarecommendation":"\u041f\u0440\u0435\u043f\u043e\u0440\u044a\u043a\u0430",//no i18n
	"crm.filter.label.all.products":"\u0412\u0441\u0438\u0447\u043a\u0438 {0}",//no i18n
	"crm.filter.label.select.products":"\u0418\u0437\u0431\u0440\u0430\u043d\u0438 {0}",//no i18n
	"crm.reviewprocess.smart.filter":"\u0421\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043d\u0430 \u0437\u0430\u043f\u0438\u0441 \u043d\u0430 \u043f\u0440\u043e\u0446\u0435\u0441 \u043d\u0430 \u043f\u0440\u0435\u0433\u043b\u0435\u0436\u0434\u0430\u043d\u0435",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u041c\u043e\u043b\u044f, \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442.",//no i18n
	"crm.segmentation.segment.score":"\u0420\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u0437\u0430 \u0441\u0435\u0433\u043c\u0435\u043d\u0442",//no i18n
	"crm.filter.label.in":"\u0432\u044a\u0437 \u043e\u0441\u043d\u043e\u0432\u0430 \u043d\u0430",//no i18n
	"crm.filter.label.and.purchase.in":"\u0438 \u0438\u043c\u0430 \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442 \u0437\u0430 \u0437\u0430\u043a\u0443\u043f\u0443\u0432\u0430\u043d\u0435 \u0432",//no i18n
	"crm.filter.label.last.purchased":"\u0438 \u043d\u0430\u0441\u043a\u043e\u0440\u043e \u043f\u0440\u0438\u0434\u043e\u0431\u0438\u0442\u0438",//no i18n
	"crm.filter.label.a.day":"\u0435\u0434\u0438\u043d \u0434\u0435\u043d",//no i18n
	"crm.filter.label.a.week":"\u0435\u0434\u043d\u0430 \u0441\u0435\u0434\u043c\u0438\u0446\u0430",//no i18n
	"crm.filter.label.a.month":"\u0435\u0434\u0438\u043d \u043c\u0435\u0441\u0435\u0446",//no i18n
	"crm.cal.custom":"\u041f\u043e \u0438\u0437\u0431\u043e\u0440",//no i18n
	"crm.mb.field.common.empt":"\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u0440\u0430\u0437\u043d\u0430.",//no i18n
	"crm.chosen.error.loading.text":"\u041d\u0435 \u0443\u0441\u043f\u044f\u0445\u043c\u0435 \u0434\u0430 \u0437\u0430\u0440\u0435\u0434\u0438\u043c \u0432\u0430\u0448\u0438\u0442\u0435 \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442\u0438",//no i18n
	"crm.filter.label.firstbuy":"\u0421\u043e\u0431\u0441\u0442\u0432\u0435\u043d\u043e \u0438\u043c\u0435",//no i18n
	"crm.filter.label.cwbab":"\u0417\u0430\u0432\u0438\u0441\u0438\u043c\u043e",//no i18n
	"crm.filter.label.fbt":"\u041a\u043e\u043c\u043f\u043b\u0435\u043a\u0442\u0443\u0432\u0430\u043d\u0435",//no i18n
	"crm.filter.label.rebuy":"\u041f\u043e\u0432\u0442\u0430\u0440\u044f\u043d\u0435",//no i18n
	"crm.filter.label.nextbuy":"\u041f\u043e\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u043d\u043e\u0441\u0442",//no i18n
	"crm.mxnlookup.select" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0430\u0432\u0430\u043d\u0435 {0}",//No I18n
	"crm.lookup.chooserecord":"\u0418\u0437\u0431\u043e\u0440 \u043d\u0430 {0}",//no i18n
	"crm.record.selected":"{0} \u0418\u0437\u0431\u0440\u0430\u043d\u043e {0}",//no i18n
	"crm.module.empty.message" : "\u041d\u044f\u043c\u0430 \u043d\u0430\u043c\u0435\u0440\u0435\u043d\u0438 {0}",//No I18n
	"crm.mxnlookup.selected" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0435\u043d {0}",//No I18n
	"crm.security.error" : "\u041d\u044f\u043c\u0430\u0442\u0435 \u0434\u043e\u0441\u0442\u0430\u0442\u044a\u0447\u043d\u043e \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f \u0434\u0430 \u0438\u0437\u0432\u044a\u0440\u0448\u0432\u0430\u0442\u0435 \u0442\u043e\u0432\u0430 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435. \u0421\u0432\u044a\u0440\u0436\u0435\u0442\u0435 \u0441\u0435 \u0441 \u0412\u0430\u0448\u0438\u044f \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440.", //no i18n
	"crm.label.creator.noPermission" :  "\u0414\u043e\u0441\u0442\u044a\u043f\u044a\u0442 \u0435 \u043e\u0442\u043a\u0430\u0437\u0430\u043d", //no i18n
	"crm.segmentation.recency" : "\u0410\u043a\u0442\u0443\u0430\u043b\u043d\u043e\u0441\u0442",  //no i18n
	"crm.segmentation.frequency" : "\u0427\u0435\u0441\u0442\u043e\u0442\u0430",  //no i18n
	"crm.segmentation.monetary" : "\u0412\u0430\u043b\u0443\u0442\u0430",  //no i18n
	"crm.smartfilter.related.module.msg" : "\u041d\u0435 \u043c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 \u0442\u0440\u0438 \u0441\u0432\u044a\u0440\u0437\u0430\u043d\u0438 \u043c\u043e\u0434\u0443\u043b\u0430.",//no i18n
	"crm.smartfilter.related.module.msg1" : "(\u041d\u0430\u043f\u0440\u0438\u043c\u0435\u0440: \u0438\u043c\u0435\u0439\u043b, \u0434\u0435\u0439\u043d\u043e\u0441\u0442\u0438, \u0431\u0435\u043b\u0435\u0436\u043a\u0438)" ,//no i18n
	"crm.smartfilter.related.module.msg2" : "\u041f\u0440\u043e\u0434\u044a\u043b\u0436\u0438\u0442\u0435\u043b\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0435 \u043f\u0440\u0430\u0437\u043d\u0430", //no i18n
	"crm.label.timeZone": "\u0427\u0430\u0441\u043e\u0432\u0430 \u0437\u043e\u043d\u0430", //NO I18N
	"crm.label.insufficient.privileges": "\u041d\u0435\u0434\u043e\u0441\u0442\u0430\u0442\u044a\u0447\u043d\u0438 \u043f\u0440\u0430\u0432\u0430 \u0437\u0430 \u0438\u0437\u043f\u044a\u043b\u043d\u0435\u043d\u0438\u0435 \u043d\u0430 \u0442\u0430\u0437\u0438 \u043e\u043f\u0435\u0440\u0430\u0446\u0438\u044f. \u0421\u0432\u044a\u0440\u0436\u0435\u0442\u0435 \u0441\u0435 \u0441 \u0432\u0430\u0448\u0438\u044f \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440.", //NO I18n
	"crm.filter.header.secton.system": "\u0414\u0435\u0444\u0438\u043d\u0438\u0440\u0430\u043d\u0438 \u043e\u0442 \u0441\u0438\u0441\u0442\u0435\u043c\u0430\u0442\u0430 \u043f\u0440\u0430\u0432\u0438\u043b\u0430", //NO I18N
	"crm.filter.header.secton.fields": "\u0424\u0438\u043b\u0442\u0440\u0438\u0440\u0430\u043d\u0435 \u043f\u043e \u043f\u043e\u043b\u0435\u0442\u0430", //NO I18N
	"crm.createfield.calcinfo.new" : "\u0422\u043e\u0432\u0430 \u043f\u043e\u043b\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0430 \u043a\u0430\u0442\u043e \u043a\u0430\u043b\u043a\u0443\u043b\u0430\u0442\u043e\u0440 \u0437\u0430 \u0432\u0441\u0435\u043a\u0438 \u0438\u0437\u0440\u0430\u0437, \u043a\u043e\u0439\u0442\u043e \u0432\u044a\u0432\u0435\u0434\u0435\u0442\u0435.</br> <b>\u041d\u0430\u043f\u0440. 20+20</b> \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u043d\u043e \u0449\u0435 \u0434\u043e\u0432\u0435\u0434\u0435 \u0434\u043e <b>40</b>",//No i18n
	"crm.lable.read.only" : "\u041f\u043e\u043b\u0435 \u0441\u0430\u043c\u043e \u0437\u0430 \u0447\u0435\u0442\u0435\u043d\u0435",//No i18n
	"crm.column.sort.asc" : "ASC",//No I18n
	"crm.column.sort.desc" : "\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435",//No i18n
	"crm.column.unsort" : "\u041d\u0435\u0441\u043e\u0440\u0442\u0438\u0440\u0430\u043d\u043e",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u0421\u043f\u043e\u0434\u0435\u043b\u044f\u043d\u0435 \u0441 \u043a\u043b\u0438\u0435\u043d\u0442", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "\u0421\u0430\u043c\u043e {0} \u0441\u0438\u043c\u0432\u043e\u043b\u0438 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438 \u0437\u0430{1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u0421\u043f\u043e\u0434\u0435\u043b\u0435\u043d\u043e \u0435 \u0441 \u043a\u043b\u0438\u0435\u043d\u0442", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "\u0422\u0430\u0437\u0438 \u0440\u043e\u043b\u044f \u0432\u0435\u0447\u0435 \u0435 \u0438\u0437\u0431\u0440\u0430\u043d\u0430", //no i18n
	"crm.user.deleted": "\u041f\u041e\u0422\u0420\u0415\u0411\u0418\u0422\u0415\u041b\u042f\u0422 \u0415 \u0418\u0417\u0422\u0420\u0418\u0422",  //NO I18N
	"crm.account.closed": "\u0422\u041e\u0417\u0418 \u0410\u041a\u0410\u0423\u041d\u0422 \u0415 \u0417\u0410\u0422\u0412\u041e\u0420\u0415\u041d",  //NO I18N
	"crm.start.chat": "\u0421\u0442\u0430\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u0447\u0430\u0442",  //NO I18N
	"crm.start.call": "\u0421\u0442\u0430\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u043f\u043e\u0432\u0438\u043a\u0432\u0430\u043d\u0435",  //NO I18N
	"crm.recipient.invalid.email" : "\u041e\u0442\u043a\u0440\u0438\u0442\u0438 \u0441\u0430 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0438 \u0438\u043c\u0435\u0439\u043b\u0438.", //NO I18N
	"crm.recipient.add.recipient" : "\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0434\u043e\u043f\u044a\u043b\u043d\u0438\u0442\u0435\u043b\u0435\u043d \u043f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b", //NO I18N
	"crm.start.video.call": "\u0421\u0442\u0430\u0440\u0442\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u0432\u0438\u0434\u0435\u043e\u043f\u043e\u0432\u0438\u043a\u0432\u0430\u043d\u0435",  //NO I18N //ignorei18n_start
	"Score":"\u0420\u0435\u0437\u0443\u043b\u0442\u0430\u0442",
	"Positive Score":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442",
	"Negative Score":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442",
	"Touch Point Score":"\u0420\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u0437\u0430 \u0442\u043e\u0447\u043a\u0438 \u043d\u0430 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",
	"Positive Touch Point Score":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u0437\u0430 \u0442\u043e\u0447\u043a\u0438 \u043d\u0430 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",
	"Negative Touch Point Score":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u0435\u043d \u0440\u0435\u0437\u0443\u043b\u0442\u0430\u0442 \u0437\u0430 \u0442\u043e\u0447\u043a\u0438 \u043d\u0430 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",
	"crm.label.scoring.rules":"\u041f\u0440\u0430\u0432\u0438\u043b\u0430 \u0437\u0430 \u0442\u043e\u0447\u043a\u0443\u0432\u0430\u043d\u0435",
	"crm.label.type.minutes": "\u041f\u0438\u0448\u0435\u0442\u0435 \u0442\u0443\u043a \u0437\u0430 \u043c\u0438\u043d\u0443\u0442\u0438", //NO I18N
	"is OPEN" : "is OPEN", //no i18n
	"is WON" : "is WON", //no i18n
	"is LOST" : "is LOST", //no i18n
	"crm.potential.all.open":"All Open stages",//no i18n
	"crm.potential.all.won":"All Closed Won stages",//no i18n
	"crm.potential.all.lost":"All Closed Lost stages", //no i18n

	"crm.campaign.member.status" : "\u0421\u0442\u0430\u0442\u0443\u0441 \u043d\u0430 \u0447\u043b\u0435\u043d",//no i18n
	"crm.dashboard.select.type" : "\u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 {0}",//no i18n
	"crm.campaign.service.status":"\u0421\u044a\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043d\u0430 \u0443\u0441\u043b\u0443\u0433\u0430",//no i18n

	"crm.label.addColumn":"\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u043a\u043e\u043b\u043e\u043d\u0430",//no i18n
	"crm.button.clear.filter":"\u0417\u0430\u0442\u0432\u0430\u0440\u044f\u043d\u0435 \u043d\u0430 \u0444\u0438\u043b\u0442\u044a\u0440",//no i18n
	"crm.button.show.filter":"\u041f\u043e\u043a\u0430\u0437\u0432\u0430\u043d\u0435 \u043d\u0430 \u0444\u0438\u043b\u0442\u044a\u0440",//no i18n
	"crm.las.error.user.maxlimit":"\u041c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043c\u0430\u043a\u0441\u0438\u043c\u0443\u043c 20 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u044f.",//no i18n
	"crm.las.error.picklist.maxlimit":"\u041c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043c\u0430\u043a\u0441\u0438\u043c\u0443\u043c 20 \u043e\u043f\u0446\u0438\u0438.",//no i18n

	"crm.fileuploader.message.responseerror": "\u041d\u0435\u0443\u0441\u043f\u0435\u0448\u043d\u043e \u043a\u0430\u0447\u0432\u0430\u043d\u0435.", //NO I18N
	"crm.storage.create.error":"\u041d\u0435 \u043c\u043e\u0433\u0430\u0442 \u0434\u0430 \u0431\u044a\u0434\u0430\u0442 \u0441\u044a\u0437\u0434\u0430\u0434\u0435\u043d\u0438 \u043d\u043e\u0432\u0438 \u0437\u0430\u043f\u0438\u0441\u0438, \u0442\u044a\u0439 \u043a\u0430\u0442\u043e \u0441\u0442\u0435 \u0434\u043e\u0441\u0442\u0438\u0433\u043d\u0430\u043b\u0438 \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u043e\u0442\u043e \u0441\u0438 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 \u0437\u0430 \u043c\u044f\u0441\u0442\u043e \u0437\u0430 \u0441\u044a\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u0435 \u043d\u0430 \u0434\u0430\u043d\u043d\u0438.",//no i18n
	"crm.storage.create.error.client":"\u041d\u044f\u043c\u0430 \u0441\u044a\u0437\u0434\u0430\u0434\u0435\u043d\u0438 \u043d\u043e\u0432\u0438 \u0437\u0430\u043f\u0438\u0441\u0438, \u0437\u0430\u0449\u043e\u0442\u043e \u0432\u0430\u0448\u0438\u044f\u0442 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440 \u0435 \u0434\u043e\u0441\u0442\u0438\u0433\u043d\u0430\u043b \u0441\u0432\u043e\u0435\u0442\u043e \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u043e \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 \u0437\u0430 \u043c\u044f\u0441\u0442\u043e \u0437\u0430 \u0441\u044a\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u0435. \u0421\u0432\u044a\u0440\u0436\u0435\u0442\u0435 \u0441\u0435 \u0441 {0}, \u0437\u0430 \u0434\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0438\u0442\u0435 \u0442\u043e\u0437\u0438 \u043f\u0440\u043e\u0431\u043b\u0435\u043c.",//no i18n
	"crm.storage.avail.info":"({0} \u043e\u0441\u0442\u0430\u0432\u0430\u0449\u0438 \u043e\u0442 {1})",//no i18n
	"crm.storage.error.key.manage":"\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430 \u0432\u0430\u0448\u0435\u0442\u043e \u043c\u044f\u0441\u0442\u043e \u0437\u0430 \u0441\u044a\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u0435 \u043d\u0430 \u0434\u0430\u043d\u043d\u0438",//no i18n
	"Records":"\u0417\u0430\u043f\u0438\u0441\u0438",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u0414\u043e\u043f\u044a\u043b\u043d\u0438\u0442\u0435\u043b\u043d\u0438 \u043f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b\u0438", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u0418\u0437\u043f\u043e\u043b\u0437\u0432\u0430\u0439\u0442\u0435 \u0437\u0430\u043f\u0435\u0442\u0430\u044f, \u0437\u0430 \u0434\u0430 \u0440\u0430\u0437\u0434\u0435\u043b\u044f\u0442\u0435 \u0434\u043e\u043f\u044a\u043b\u043d\u0438\u0442\u0435\u043b\u043d\u0438\u0442\u0435 \u0438\u043c\u0435\u0439\u043b\u0438.", //NO I18N
	"crm.related.contact.account" : "{0} \u0441\u0432\u044a\u0440\u0437\u0430\u043d\u0438 \u0441 {1}",//No I18n
	"crm.allcontact.show" : "\u0412\u0441\u0438\u0447\u043a\u0438 {0}",//No I18n
	"crm.button.mass.show" : "\u041f\u043e\u043a\u0430\u0436\u0438",//No I18n
	"crm.msg.custom.view.not.replied" : "\u041d\u044f\u043c\u0430 \u043e\u0442\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u0438 \u0441\u044a\u043e\u0431\u0449\u0435\u043d\u0438\u044f", //NO I18N
	"crm.msg.custom.view.replied" : "\u041e\u0442\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u0438 \u0441\u044a\u043e\u0431\u0449\u0435\u043d\u0438\u044f",//NO I18N
	"crm.workflow.select.recipients" : "\u041f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b\u0438", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u041c\u043e\u043b\u044f, \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u043d\u0435 \u0435\u0434\u0438\u043d \u043f\u0440\u043e\u0444\u0438\u043b.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u0417\u0430 \u0441\u044a\u0436\u0430\u043b\u0435\u043d\u0438\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u043f\u0440\u0435\u043c\u0430\u0445\u043d\u0435\u0442\u0435 \u043f\u0440\u043e\u0444\u0438\u043b \u043f\u043e \u043f\u043e\u0434\u0440\u0430\u0437\u0431\u0438\u0440\u0430\u043d\u0435.",//NO I18N
	"crm.inv.label.add.emails" : "\u0414\u043e\u0431\u0430\u0432\u044f\u043d\u0435 \u043d\u0430 \u0438\u043c\u0435\u0439\u043b\u0438", //NO I18N
	"crm.prediction.analytics.filter.year":"\u041c\u0438\u043d\u0430\u043b\u0430\u0442\u0430 \u0433\u043e\u0434\u0438\u043d\u0430",//no i18n
	"Previous\ FY":"\u041f\u0440\u0435\u0434\u0445\u043e\u0434\u043d\u0430 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u0430 \u0433\u043e\u0434\u0438\u043d\u0430",//no i18n
	"Current\ FY":"\u0422\u0435\u043a\u0443\u0449\u0430 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u0430 \u0433\u043e\u0434\u0438\u043d\u0430",//no i18n
	"Next\ FY":"\u0421\u043b\u0435\u0434\u0432\u0430\u0449\u0430 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u0430 \u0433\u043e\u0434\u0438\u043d\u0430",//no i18n
	"Current\ FQ":"\u0422\u0435\u043a\u0443\u0449 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432 \u043a\u043e\u0435\u0444\u0438\u0446\u0438\u0435\u043d\u0442",//no i18n
	"Next\ FQ":"\u0421\u043b\u0435\u0434\u0432\u0430\u0449 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432 \u043a\u043e\u0435\u0444\u0438\u0446\u0438\u0435\u043d\u0442",//no i18n
	"Previous\ FQ":"\u041f\u0440\u0435\u0434\u0438\u0448\u0435\u043d \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432 \u043a\u043e\u0435\u0444\u0438\u0446\u0438\u0435\u043d\u0442",//no i18n
	"crm.picklist.sample.text":"\u041f\u0440\u0438\u043c\u0435\u0440\u0435\u043d \u0442\u0435\u043a\u0441\u0442",//no i18n
	"crm.more.colors":"\u041e\u0449\u0435 \u0446\u0432\u0435\u0442\u043e\u0432\u0435",//no i18n
	"crm.button.back.alone":"\u041e\u0431\u0440\u0430\u0442\u043d\u043e",//no i18n
	"crm.field.label.email":"\u0418\u043c\u0435\u0439\u043b",//no i18n
"crm.zia.nba.feature.label":"\u0421\u043b\u0435\u0434\u0432\u0430\u0449\u043e \u043d\u0430\u0439-\u0434\u043e\u0431\u0440\u043e \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",//no i18n
"Meeting":"\u0421\u0440\u0435\u0449\u0430",//no i18n
"Tomorrow":"\u0423\u0442\u0440\u0435",//no i18n
"crm.gdpr.notavailable.field":"\u041d\u0435 \u0435 \u043d\u0430\u043b\u0438\u0447\u043d\u043e",//no i18n
	"crm.setup.system.ziasimilarity":"\u041f\u0440\u0435\u043f\u043e\u0440\u044a\u043a\u0430 \u0437\u0430 \u0441\u0445\u043e\u0434\u0441\u0442\u0432\u043e",//no i18n
	"crm.gdpr.notavailable.field":"\u041d\u0435 \u0435 \u043d\u0430\u043b\u0438\u0447\u043d\u043e",//no i18n
	"crm.filter.label.all.products":"\u0412\u0441\u0438\u0447\u043a\u0438 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"zia.similarity.smartfilter.score":"\u041e\u0446\u0435\u043d\u043a\u0430 \u043d\u0430 \u043f\u043e\u0434\u043e\u0431\u043d\u043e\u0441\u0442",//no i18n
	"zia.similarity.smartfilter.records":"\u041f\u043e\u0434\u043e\u0431\u043d\u043e {0} \u043d\u0430",//no i18n
	"zia.similarity.smartfilter.records.search":"\u041f\u043e\u043a\u0430\u0437\u0432\u0430\u043d\u0435 \u043d\u0430 \u043f\u043e\u0434\u043e\u0431\u043d\u0438 {0} \u043d\u0430",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u041d\u0435 \u043c\u043e\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 {0} {1}",//no i18n,
	"crux.custom.field.greater.than.equalto":"{0} трябва да бъде по-голямо от или равно на {1}.",
	"crux.users.selected.plural" : "{0} избрани потребители.",
	"crux.user.selected.singular" :"{0} избран потребител.",
	"crux.criteria.empty.secondaryfield.module" : "Няма открито съвпадащо {0} поле в модула {1}",
	"crux.criteria.empty.secondaryfield" : "Няма налично друго {0} поле за сравнение, моля, въведете стойност за сравнение.",
	"crux.logged.in.role.definition" : "Роля на потребителя, който инициира действия по записите",
	"crux.max.limit.unselect" : "Не можете да отмените избора за повече от {0} {1}.", //no i18n
	"crux.existing.tag" : "\u201e{0}\u201d е вече избрано"	//no i18n
}
